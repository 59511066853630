<template id="email">
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Tìm kiếm"
              @input="search($event)"
            />
          </b-input-group>
        </div>
      </div>

      <!-- App Action Bar -->
      <div class="app-action">
        <div class="action-left item-check-box-email">
          <b-form-checkbox
            v-model="checkAll"
            :indeterminate="showBtnMultiDelete"
            @input="deleteAll(listEmailInbox)"
          >
            Chọn tất cả
          </b-form-checkbox>
        </div>
        <div
          v-show="showBtnMultiDelete"
          class="align-items-center"
          :class="{'d-flex': showBtnMultiDelete}"
        >

          <feather-icon
            icon="Trash2Icon"
            size="17"
            class="cursor-pointer ml-1"
            @click="deleteItems"
          />

        </div>
      </div>

      <!-- Emails List -->
      <div
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul
          ref="message"
          class="email-media-list"
        >
          <b-media
            v-for="(email, index) in listEmailInbox"
            :key="index"
            tag="li"
            no-body
            :class="{ 'mail-read': email.isRead }"
            @click="detailEmailViewData(email)"
          >
            <b-col>
              <b-media-aside class="media-left mr-50">
                <b-row>
                  <b-avatar
                    class="avatar"
                    size="40"
                    variant="primary"
                    :src="($serverfile+email.avatar)"
                  />
                </b-row>
                <b-row>
                  <div class="user-action item-check-box-email">
                    <b-form-checkbox
                      v-model="email.isChecked"
                      @input="checkedIdDelete(email)"
                      @click.native.stop
                    />
                  </div>
                </b-row>
              </b-media-aside>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-media-body>
                <div class="mail-details">
                  <div
                    v-if="email.viewDate === null"
                    class="mail-items"
                    style="width: 95%"
                  >

                    <b-row>
                      <b-col>

                        <h5 class="mb-25 font-weight-email">
                          {{ email.fullName }}
                        </h5>

                      </b-col>

                      <div class="float-right mail-message color-text-email">
                        <span>{{ email.sentDate | formatDateToDDMMHHMM }}</span>
                      </div>
                    </b-row>
                    <span class="text-truncate font-weight-email">{{ email.subject }}</span>
                  </div>
                  <div
                    v-else
                    class="mail-items"
                    style="width: 95%"
                  >

                    <b-row>
                      <b-col>
                        <h5 class="mb-25 color-text-email">
                          {{ email.fullName }}
                        </h5>
                      </b-col>

                      <div class="float-right mail-message color-text-email">
                        <span>{{ email.sentDate | formatDateToDDMMHHMM }}</span>
                      </div>
                    </b-row>
                    <span class="text-truncate text-color-content color-text">{{ email.subject }}</span>
                  </div>
                  <div class="mail-meta-item">
                    <feather-icon
                      v-if="email.filePaths"
                      icon="PaperclipIcon"
                    />
                    <span
                      v-for="label in email.labels"
                      :key="label"
                      class="mx-50 bullet bullet-sm"
                      :class="`bullet-${resolveLabelColor(label)}`"
                    />
                  </div>
                </div>

                <div class="mail-message">
                  <p
                    class="text-truncate text-color-content mb-0"
                    v-html="email.content"
                  >
                  </p>
                </div>
              </b-media-body>
            </b-col>

          </b-media>
        </ul>
        <!-- pagination -->
        <my-pagination
          v-if="!showEmailDetails"
          :totalItems="totalRecordInbox"
          :currentPage="urlQuery.pageNumber"
          @pageClick="pageChange"
        />
      </div>
    </div>

    <!-- Email View/Detail -->
    <email-view
      :class="{'show': showEmailDetails}"
      :email-view-data="dataEmailDetail"
      :opended-email-meta="opendedEmailMeta"
      @showModalRepEmail="showModalSend"
      @showModalSendMail="showModalSend"
      @reload-table="reloadTable"
      @close-email-view="closeEmailView"
      @move-email-to-folder="moveOpenEmailToFolder"
      @toggle-email-starred="toggleStarred(emailViewData)"
      @update-email-label="updateOpenEmailLabel"
      @mark-email-unread="markOpenEmailAsUnread"
      @change-opened-email="changeOpenedEmail"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar
        :emails-meta="emailsMeta"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-email-view="closeEmailView"
        @showModalSend="showModalSend"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
    <!-- Compose Email Modal -->
    <email-compose
      :id="emailComposeId"
      :fullDataDetail="dataEmailDetail"
    />
  </div>
</template>

<script>
import {
  ref, onUnmounted, computed, watch,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  filterTags, formatDateToMonthShort, formatFullName, formatDateToDDMMHHMM,
} from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import EmailLeftSidebar from './layout/EmailLeftSidebar.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import EmailView from './components/EmailView.vue'
import emailStoreModule from '../store/emailStoreModule'
import useEmail from '../store/useEmail'
import EmailCompose from './components/EmailCompose.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constant/ConstantsApi'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    MyPagination,
    BRow,
    BCol,
    ConfirmModal,
    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    EmailLeftSidebar,
    EmailView,
    EmailCompose,

  },
  setup() {
    const EMAIL_APP_STORE_MODULE_NAME = 'app-email'

    // Register module
    if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.registerModule(EMAIL_APP_STORE_MODULE_NAME, emailStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const { resolveLabelColor } = useEmail()

    // Route Params
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchEmails()
    })

    // Emails & EmailsMeta
    const emails = ref([])
    const emailsMeta = ref({})

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch(searchQuery, () => fetchEmails())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const fetchEmails = () => {
      store.dispatch('app-email/fetchEmails', {
        q: searchQuery.value,
        folder: router.currentRoute.params.folder || 'inbox',
        label: router.currentRoute.params.label,
      })
        .then(response => {
          emails.value = response.data.emails
          emailsMeta.value = response.data.emailsMeta
        })
    }

    fetchEmails()

    // ------------------------------------------------
    // Mail Selection
    // ------------------------------------------------
    const selectedEmails = ref([])
    const toggleSelectedMail = mailId => {
      const mailIndex = selectedEmails.value.indexOf(mailId)
      if (mailIndex === -1) selectedEmails.value.push(mailId)
      else selectedEmails.value.splice(mailIndex, 1)
    }
    const selectAllEmailCheckbox = computed(() => emails.value.length && (emails.value.length === selectedEmails.value.length))
    const isSelectAllEmailCheckboxIndeterminate = computed(() => Boolean(selectedEmails.value.length) && emails.value.length !== selectedEmails.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedEmails.value = val ? emails.value.map(mail => mail.id) : []
    }
    // ? Watcher to reset selectedEmails is somewhere below due to watch dependecy fullfilment

    // ------------------------------------------------
    // Mail Actions
    // ------------------------------------------------
    const toggleStarred = email => {
      store.dispatch('app-email/updateEmail', {
        emailIds: [email.id],
        dataToUpdate: { isStarred: !email.isStarred },
      }).then(() => {
        // eslint-disable-next-line no-param-reassign
        email.isStarred = !email.isStarred
      })
    }

    const moveSelectedEmailsToFolder = folder => {
      store.dispatch('app-email/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: { folder },
      })
        .then(() => { fetchEmails() })
        .finally(() => { selectedEmails.value = [] })
    }

    const updateSelectedEmailsLabel = label => {
      store.dispatch('app-email/updateEmailLabels', {
        emailIds: selectedEmails.value,
        label,
      })
        .then(() => { fetchEmails() })
        .finally(() => { selectedEmails.value = [] })
    }

    const markSelectedEmailsAsUnread = () => {
      store.dispatch('app-email/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: { isRead: false },
      })
        .then(() => { fetchEmails() })
        .finally(() => { selectedEmails.value = [] })
    }

    // ------------------------------------------------
    // Email Details
    // ------------------------------------------------
    const showEmailDetails = ref(false)
    const emailViewData = ref({} || null)
    const opendedEmailMeta = computed(() => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      return {
        hasNextEmail: Boolean(emails.value[openedEmailIndex + 1]),
        hasPreviousEmail: Boolean(emails.value[openedEmailIndex - 1]),
      }
    })
    const updateEmailViewData = email => {
      // Mark email is read
      store.dispatch('app-email/updateEmail', {
        emailIds: [email.id],
        dataToUpdate: { isRead: true },
      })
        .then(() => {
          // If opened email is unread then decrease badge count for email meta based on email folder
          if (!email.isRead && (email.folder === 'inbox' || email.folder === 'spam')) {
            emailsMeta.value[email.folder] -= 1
          }

          // eslint-disable-next-line no-param-reassign
          email.isRead = true
        })
        .finally(() => {
          emailViewData.value = email
          showEmailDetails.value = true
        })
    }
    const moveOpenEmailToFolder = folder => {
      selectedEmails.value = [emailViewData.value.id]
      moveSelectedEmailsToFolder(folder)
      selectedEmails.value = []
      showEmailDetails.value = false
    }
    const updateOpenEmailLabel = label => {
      selectedEmails.value = [emailViewData.value.id]
      updateSelectedEmailsLabel(label)

      // Update label in opened email
      const labelIndex = emailViewData.value.labels.indexOf(label)
      if (labelIndex === -1) emailViewData.value.labels.push(label)
      else emailViewData.value.labels.splice(labelIndex, 1)

      selectedEmails.value = []
    }

    const markOpenEmailAsUnread = () => {
      selectedEmails.value = [emailViewData.value.id]
      markSelectedEmailsAsUnread()

      selectedEmails.value = []
      showEmailDetails.value = false
    }

    const changeOpenedEmail = dir => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      const newEmailIndex = dir === 'previous' ? openedEmailIndex - 1 : openedEmailIndex + 1
      emailViewData.value = emails.value[newEmailIndex]
    }

    // * If someone clicks on filter while viewing detail => Close the email detail view
    watch(routeParams, () => {
      showEmailDetails.value = false
    })

    // * Watcher to reset selectedEmails
    // ? You can also use showEmailDetails (instead of `emailViewData`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([emailViewData, routeParams], () => {
      selectedEmails.value = []
    })

    // Compose
    const shallShowEmailComposeModal = ref(false)

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // UI
      perfectScrollbarSettings,

      // Emails & EmailsMeta
      emails,
      emailsMeta,

      // Mail Selection
      selectAllEmailCheckbox,
      isSelectAllEmailCheckboxIndeterminate,
      selectedEmails,
      toggleSelectedMail,
      selectAllCheckboxUpdate,

      // Mail Actions
      toggleStarred,
      moveSelectedEmailsToFolder,
      updateSelectedEmailsLabel,
      markSelectedEmailsAsUnread,

      // Email Details
      showEmailDetails,
      emailViewData,
      opendedEmailMeta,
      updateEmailViewData,
      moveOpenEmailToFolder,
      updateOpenEmailLabel,
      markOpenEmailAsUnread,
      changeOpenedEmail,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
    }
  },

  filters: {
    formatDateToDDMMHHMM,
  },

  data() {
    return {
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      listEmailInbox: [],
      totalRecordInbox: 0,
      deleteIds: [],
      showBtnMultiDelete: false,
      confirmModalId: 'modal-delete-inbox',
      modalContent: '',
      checkAll: false,
      // dataCountNotify: 0,
      showEmailDetails: false,
      idDetail: '',
      dataEmailDetail: {},
      emailComposeId: 'compose-mail',
      fullDataDetail: null,
      sendId: null,
    }
  },

  watch: {

    deleteIds(val) {
      if (val.length > 0) {
        this.showBtnMultiDelete = true
      } else {
        this.showBtnMultiDelete = false
      }
    },
  },

  mounted() {
    this.fetchNewNotifiction()
  },

  async created() {
    this.fetchEmailInbox(this.urlQuery)
  },

  methods: {
    ...mapActions('app', ['fetchNewNotifiction']),
    // Lấy data hộ thư đến
    async fetchEmailInbox(urlQuery) {
      const res = await axiosApiInstance.get(ConstantsApi.API_GET_INBOX, {
        params: urlQuery,
      })
      this.listEmailInbox = res?.data?.data?.pageLists
      this.listEmailInbox.forEach(item => { item.isChecked = false })
      this.totalRecordInbox = res?.data?.data?.totalRecord
      // this.dataEmailDetail = {}
    },

    // Hiển thị modal Gửi emai
    showModalSend() {
      // this.sendId = this.dataEmailDetail.id
      this.$bvModal.show(this.emailComposeId)
    },

    // Lấy số lượng tin nhắn mới
    // async fetchNewInbox() {
    //   const { data } = await axiosApiInstance.get(ConstantsApi.QUANTITY_INBOX)
    //   this.dataCountNotify = data
    // },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchEmailInbox(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchEmailInbox(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchEmailInbox(this.urlQuery)
      }
    },

    // Lấy danh sách id
    checkedIdDelete(val) {
      if (val.isChecked) {
        this.deleteIds.push(val.inboxId)
      } else {
        this.deleteIds = this.deleteIds.filter(item => item !== val.inboxId)
      }
    },

    // lấy hết id
    deleteAll(val) {
      if (this.checkAll) {
        this.deleteIds = val.map(e => e.inboxId)
        this.listEmailInbox.forEach(item => { item.isChecked = true })
      } else {
        this.deleteIds = []
        this.listEmailInbox.forEach(item => { item.isChecked = false })
      }
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.modalContent = this.$t('Bạn có muốn xóa không?')
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_INBOX, this.deleteIds).then(response => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.deleteIds = []
        this.checkAll = false
        this.fetchEmailInbox(this.urlQuery)
        this.fetchNewInbox()
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.error[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Lấy dữ liệu một email
    async detailEmailViewData(val) {
      this.idDetail = val.inboxId
      this.$showAllPageLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.DETAIL_GET_INBOX, {
        params: { inboxId: this.idDetail },
      })
      this.dataEmailDetail = data
      this.$hideAllPageLoading()
      // this.fullDataDetail = data
      this.showEmailDetails = true
      if (!val.viewDate) {
        this.fetchNewNotifiction()
      }
    },

    closeEmailView() {
      this.showEmailDetails = false
      this.dataEmailDetail = {}
      // this.fullDataDetail = null
      this.fetchEmailInbox(this.urlQuery)
    },

    // Reset table
    reloadTable() {
      this.fetchEmailInbox(this.urlQuery)
    },
  },
}
</script>

<style lang="scss" >
@import "~@core/scss/base/pages/app-email.scss";
#email{
   .custom-control{
    margin-left: 10px !important;
  }
}
.item-check-box-email{
  margin-left: 10px;
}
.font-weight-email{
  font-weight: bold;
  font-style: normal;
}

.color-text{
  color: #b9b9c3 ;
}

.email-user-list {
  display: flex;
  flex-direction: column;
  .email-media-list {
    overflow-y: auto;
    overflow-x: hidden;
    height: 650px;
  }
}

.color-text-email {
  color: #797E84 !important;
}

.text-color-content {
  color: #797E84 !important;
}

</style>
